import React, { useState } from 'react';
import ReactFlow, { MarkerType, Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import { useTranslation } from 'react-i18next';

// 1) Custom L-shaped edge with arrow
const SquareEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  markerStart,
  markerEnd,
  style,
}) => {
  const path = `M ${sourceX},${sourceY}
                L ${sourceX},${targetY}
                L ${targetX},${targetY}`;

  return (
    <g>
      <path
        id={id}
        d={path}
        fill="none"
        style={style}
        strokeWidth={2}
        markerStart={markerStart}
        markerEnd={markerEnd}
      />
    </g>
  );
};

// 2) Custom node component
//    We'll accept a new "onHover" function from the parent via data
const CustomNode = ({ data }) => {
  const { 
    label, 
    icon, 
    isMainStep, 
    isCompleted, 
    onClick, 
    description,
    onHover      // <- new prop for hover actions
  } = data;

  // Give your nodes distinct color palettes
  const backgroundColor = isMainStep
    ? 'linear-gradient(135deg, #F7FAFC 0%, #EDF2F7 100%)'
    : isCompleted
    ? 'linear-gradient(135deg, #B9F6CA 0%, #DAFBEB 100%)'
    : 'linear-gradient(135deg, #FFC1C1 0%, #FFE1E1 100%)';

  // Slightly more striking border colors
  const borderColor = isMainStep
    ? '#3182CE'   // Blue-ish for main step
    : isCompleted 
      ? '#38A169' // Green for completed
      : '#E53E3E';// Red for incomplete

  const iconSize = '50px';

  return (
    <div
      style={{
        width: '280px',
        height: '160px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: onClick ? 'pointer' : 'default',
        position: 'relative',
        borderRadius: '12px',
        // background: backgroundColor,
        border: `4px solid ${borderColor}`,
        boxShadow: '0 4px 6px rgba(50,50,93,0.11), 0 1px 3px rgba(0,0,0,0.08)',
        textAlign: 'center',
        transition: 'transform 0.2s, box-shadow 0.2s',
        padding: '15px',
      }}
      onClick={onClick}
      // When the mouse enters/leaves, we call the parent's onHover
      onMouseEnter={() => {
        if (onHover) {
          onHover(label, description, isCompleted);
        }
      }}
      onMouseLeave={() => {
        if (onHover) {
          onHover(null, null);
        }
      }}
      // Optional "hover" styling
      onMouseOver={(e) => {
        e.currentTarget.style.transform = 'translateY(-3px)';
        e.currentTarget.style.boxShadow =
          '0 8px 16px rgba(50,50,93,0.15), 0 3px 6px rgba(0,0,0,0.1)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.transform = 'translateY(0)';
        e.currentTarget.style.boxShadow =
          '0 4px 6px rgba(50,50,93,0.11), 0 1px 3px rgba(0,0,0,0.08)';
      }}
    >
      {icon && (
        <img
          src={icon}
          alt="icon"
          style={{ width: iconSize, height: iconSize, marginBottom: '16px' }}
        />
      )}
      <span
        style={{
          fontWeight: '600',
          fontSize: isMainStep ? '20px' : '20px',
          color: '#2D3748',
          marginBottom: '2px',
        }}
      >
        {label == "Auftragsverarbeitungsvertrag" ? 'ADVV' : label}
      </span>
      {description && (
        <span
          style={{
            fontSize: '12px',
            color: '#4A5568',
            lineHeight: '1.4',
            padding: '0 14px',
          }}
        >
          {/* {description} */}
        </span>
      )}
      {!isMainStep && (
        <div
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            fontSize: '16px',
            color: isCompleted ? '#38A169' : '#E53E3E',
          }}
        >
          {isCompleted ? '✓' : '✗'}
        </div>
      )}

      {/* Add one target handle and one source handle */}
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: '#555' }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: '#555' }}
      />
    </div>
  );
};

// Register the node + edge
const nodeTypes = { custom: CustomNode };
const edgeTypes = { square: SquareEdge };

const ProcessDiagram = ({ secondTableData, hasStandortbestimmung }) => {
  const { t } = useTranslation();

  // We'll store whichever node is hovered: { label, description }
  const [hoveredNode, setHoveredNode] = useState(null);

  // This is the callback for our custom node
  // We pass it into data so each node can call it on hover
  const handleNodeHover = (label, description, isCompleted) => {
    if (label && description) {
      setHoveredNode({ label, description, isCompleted });
    } else {
      // Clear if mouse leaves
      setHoveredNode(null);
    }
  };

  // Map createlink to icon paths
  const docIconMap = {
    verzeichnis: '/icon-verzeichnis.svg',
    br: '/icon-br.svg',
    Datenschutzfolgeabschaetzung: '/icon-dsfa.svg',
    toms: '/icon-tom.svg',
    dsa: '/icon-dse.svg',
    advv: '/icon-advv.svg',
  };

  const iconMap = {
    standortbestimmung: '/icon-sb.svg',
    audit: '/icon-br.svg',
    finished: '/icon-tom.svg', 
  };

  // Build up our nodes/edges
  const nodes = [
    {
      id: 'step-1',
      type: 'custom',
      data: {
        label: t('Standortbestimmung'),
        icon: iconMap.standortbestimmung,
        isMainStep: true,
        description: hasStandortbestimmung 
          ? t('Sie haben bereits eine Standortbestimmung erstellt.')
          : t('Erstellen Sie zuerst eine Standortbestimmung.'),
        onClick: !hasStandortbestimmung ? () => (window.location.href = '/Standortbestimmung') : undefined,

        // pass the callback so we can update hoveredNode
        onHover: handleNodeHover,
      },
      position: { x: 0, y: 0 },
    }
  ];

  const edges = [];

  let lastNodeId = 'step-1';
  let currentX = 300;
  let currentY = 0;

  if (hasStandortbestimmung) {
    // Place documents linearly from left to right
    secondTableData.forEach((item) => {
      const isCompleted = item.completed === 'Ja';
      const labelText = t(item.bereich);
      const navigateUrl = isCompleted ? `/${item.overviewlink}` : `/${item.createlink}`;
      const subIcon = docIconMap[item.createlink] || '/icon-verzeichnis.svg';
      const docDescription = t(item.description || 'No description available');

      const docNodeId = `doc-${item.actionId}`;
      nodes.push({
        id: docNodeId,
        type: 'custom',
        data: {
          label: labelText,
          icon: subIcon,
          isMainStep: false,
          isCompleted,
          description: docDescription,
          onClick: () => (window.location.href = navigateUrl),

          // pass our onHover to each node
          onHover: handleNodeHover,
        },
        position: { x: currentX, y: currentY },
      });

      edges.push({
        id: `e-${lastNodeId}-${docNodeId}`,
        source: lastNodeId,
        target: docNodeId,
        type: 'square',
        // markerEnd with an arrow
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: 'black',
          width: 20,
          height: 20,
        },
        style: { stroke: '#333', strokeWidth: 2 },
      });

      lastNodeId = docNodeId;
      currentX += 300;
    });

    // Audit Documents
    const auditNodeId = 'step-audit';
    nodes.push({
      id: auditNodeId,
      type: 'custom',
      data: {
        label: t('Audit Documents'),
        icon: iconMap.audit,
        isMainStep: true,
        // isCompleted: true, 
        description: t('Wir begutachten alle Ihre Dokumente und geben das finale OK.'),
        onHover: handleNodeHover,
      },
      position: { x: currentX, y: currentY },
    });
    edges.push({
      id: `e-${lastNodeId}-${auditNodeId}`,
      source: lastNodeId,
      target: auditNodeId,
      type: 'square',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#333',
        width: 20,
        height: 20,
      },
      style: { stroke: 'black', strokeWidth: 2 },
    });
    lastNodeId = auditNodeId;
    currentX += 300;

    // Finished step
    const finishedNodeId = 'step-finished';
    nodes.push({
      id: finishedNodeId,
      type: 'custom',
      data: {
        label: t('Finished'),
        icon: iconMap.finished,
        isMainStep: true,
        description: t('All steps are completed. Your process is finished!'),
        onHover: handleNodeHover,
      },
      position: { x: currentX, y: currentY },
    });

    edges.push({
      id: `e-${lastNodeId}-${finishedNodeId}`,
      source: lastNodeId,
      target: finishedNodeId,
      type: 'square',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#333',
        width: 20,
        height: 20,
      },
      style: { stroke: '#333', strokeWidth: 2 },
    });
  }

  console.log('Edges:', edges);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        margin: 0,
        padding: 0,
        border: 'none',
        position: 'relative', // so we can position the tooltip absolutely
        overflow: 'hidden',
        background: 'linear-gradient(120deg, #FFFFFF 0%, #FFFFFF 50%)',
      }}
    >
      {/* 
        Top-center hover panel:
        Only visible if hoveredNode != null
      */}
      {hoveredNode && (
        <div
          style={{
            position: 'absolute',
            top: '10%',
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'rgba(255, 255, 255, 0.95)',
            padding: '30px 30px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            pointerEvents: 'none', // so it doesn't block mouse
            textAlign: 'center',
            zIndex: 9999
          }}
        >
          <h4 style={{ margin: 0, fontWeight: '600', color: '#2D3748' }}>
            {hoveredNode.label}
          </h4>
          <p style={{ margin: '6px 0 0 0', color: '#444', fontSize: '14px' }}>
            {hoveredNode.description}
          </p>

          <p style={{ margin: '10px 0 0 0', color: hoveredNode.isCompleted ? '#38A169' :'#E53E3E' , fontSize: '16px' }}>
            {hoveredNode.label == 'Finished' ? (hoveredNode.isCompleted ? 'Datenschutzprozess vollständig!':'Datenschutzprozess nicht vollständig!'):
            hoveredNode.label == 'Audit Documents' ? (hoveredNode.isCompleted ? 'Audit abgeschlossen!':'Audit offen'):
            hoveredNode.isCompleted ? 'Bereits erstellt!':'Noch nicht erstellt!'}
          </p>
        </div>
      )}



      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodesDraggable={false}
        nodesConnectable={false}
        panOnDrag={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
      />

<div
          style={{
            position: 'absolute',
            top: '80%',
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'rgba(255, 255, 255, 0.95)',
            padding: '30px 30px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            pointerEvents: 'none', // so it doesn't block mouse
            textAlign: 'center',
            zIndex: 9999
          }}
        >

          <p style={{ margin: '6px 0 0 0', color: '#444', fontSize: '14px' }}>
            Fahren Sie mit dem Mauszeiger über eine Box, um weitere Details anzuzeigen. 
          </p>
        </div>


    </div>
  );
};

export default ProcessDiagram;