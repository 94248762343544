// TipBar.js
import React, { useCallback, useEffect, useState, useContext } from 'react';
import logo from '../assets/images/Logo_Red_New.png';
import { NavLink, Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useApi } from 'services/axios';
import { LanguageSelect } from './LanguangeSelect';
import ProcessDiagram from './ProcessDiagram';
import { GlobalDataContext } from '../GlobalDataProvider'; // import the global context provider

function TipBar({ shouldRefetch }) {
  const [viewingAs, setViewingAs] = useState(null);
  const [meData, setMeData] = useState(null);
  const [isCurtainVisible, setIsCurtainVisible] = useState(false); 
  const { api } = useApi();
  const { instance } = useMsal();

  // Get global data
  const { secondTableData, hasStandortbestimmung } = useContext(GlobalDataContext);

  const fetchMe = useCallback(async () => {
    if (!api) {
      console.error('API is not initialized in TipBar');
      return;
    }
    try {
      const response = await api.get('/me');

      if (response.data.isAdmin) {
        const viewingAsResponse = await api.get('/selected-user');
        setViewingAs(viewingAsResponse.data);
      }

      setMeData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [api]);

  useEffect(() => {
    if (api) {
      fetchMe();
    }
  }, [api, fetchMe]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchMe();
    }
  }, [shouldRefetch, fetchMe]);

  const handleLogout = () => {
    instance.logoutPopup();
  };

  const toggleCurtain = () => {
    setIsCurtainVisible((prev) => !prev);
  };
  console.log(secondTableData)


  return (
    <>
      <div className="neumorphic-topbar" style={{
        backgroundColor: 'white',
        padding: '46px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        minHeight: '80px',
      }}>
        {viewingAs && viewingAs.UserName && (
          <span style={{ fontWeight: "bold", color: '#c0392b', position: 'absolute', left: '5%' }}>{viewingAs.UserName}</span>
        )}

        <button
          style={{
            position: 'absolute',
            left: '20%',
            transform: 'translateX(-50%)',
            padding: '10px 20px',
            backgroundColor: '#c0392b',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            fontSize: '16px',
            cursor: 'pointer',
          }}
          onClick={toggleCurtain}
        >
          Assistent
        </button>

        <Link to="/Programme" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <img src={logo} width="240px" style={{ marginTop: '6px', marginBottom: '10px' }} alt="Logo" />
        </Link>

        <ul className="list-unstyled components" style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          listStyleType: 'none',
          padding: '0',
          position: 'absolute',
          right: '10px',
          margin: '0',
        }}>
          <li style={{ margin: '0 0px' }}>
            <LanguageSelect />
          </li>
          <li style={{ margin: '0 0px' }}>
            <NavLink to="/Dokumente" className="neumorphic-link-iconbuttons">
              <i className="fa-solid fa-folder-open" style={{ color: '#c0392b', fontSize: '20px' }}></i>
            </NavLink>
          </li>
          <li style={{ margin: '0 0px' }}>
            <NavLink to="/Profil" className="neumorphic-link-iconbuttons">
              <i className="fa-solid fa-user" style={{ color: '#c0392b', fontSize: '20px' }}></i>
            </NavLink>
          </li>
          <li style={{ margin: '0 0px' }}>
            <NavLink to="/Support" className="neumorphic-link-iconbuttons">
              <i className="fa-solid fa-phone" style={{ color: '#c0392b', fontSize: '20px' }}></i>
            </NavLink>
          </li>
          <li style={{ margin: '0 0px' }}>
            <NavLink to="/" className="neumorphic-link-iconbuttons" onClick={handleLogout}>
              <i className="fa-solid fa-sign-out" style={{ color: '#c0392b', fontSize: '20px' }}></i>
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Sliding Curtain */}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          color: 'white',
          zIndex: 1000,
          overflow: 'auto',
          opacity: isCurtainVisible ? 1 : 0,
          visibility: isCurtainVisible ? 'visible' : 'hidden',
          transition: 'opacity 1.4s ease, visibility 1.4s ease',
        }}
      >
        <button
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            padding: '10px 20px',
            backgroundColor: '#c0392b',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            zIndex: 1001,
          }}
          onClick={toggleCurtain}
        >
          Schliessen
        </button>
        <div style={{ textAlign: 'center'}}>
          {isCurtainVisible && (
            <div style={{ pointerEvents: 'auto' }}>
              {/* Use globally available data */}
              <ProcessDiagram secondTableData={secondTableData} hasStandortbestimmung={hasStandortbestimmung} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TipBar;